import './MessageCard.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { Twemoji } from 'react-emoji-render';
import { Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';

const MessageCard = ({
    post,
    background,
    onImageClick,
    platform,
}: {
    post: Post;
    background: string;
    onImageClick?: (index: number) => void;
    platform: string;
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const isOnTokenCreate = location.pathname === '/TokenCreate';

    const formatTime = (date: Date) => {
        const day = date.getDate();
        const year = date.getFullYear();
        const hours24 = date.getHours();
        const hours12 = hours24 % 12 || 12;
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const amPm = hours24 >= 12 ? 'PM' : 'AM';
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const month = months[date.getMonth()];
        return `${hours12}:${minutes} ${amPm} - ${month} ${day}, ${year}`;
    };

    const displayText = (text: string) => {
        if (!text) return null;

        const emoji = /:(0|1|2|3|4|5|6|7|8|9|10):/g;
        text = text.replaceAll('*', '');

        const parts = text.split(emoji);

        return parts.map((part, index) => {
            const trimmedPart = part.trim();
            if (index % 2 !== 0) {
                return <span key={index}>:{part}:</span>;
            }
            return trimmedPart ? (
                <span key={index}>
                    <Twemoji text={trimmedPart} />
                </span>
            ) : null;
        });
    };

    return (
        <section>
            <div className='mes-card' style={{ background }}>
                <div className='info'>
                    <h3>{post.text && displayText(post.text)}</h3>
                </div>
                <div className='img-section'>
                    {post.photos && post.photos.length > 0 ? (
                        post.photos.map((img, index) => (
                            <img
                                key={index}
                                className={`post-img ${post.photos.length === 1 ? 'single-img' : 'multiple-img'}`}
                                src={`data:image/jpeg;base64,${img}`}
                                alt='Post Image'
                                onClick={() => onImageClick && onImageClick(index)}
                            />
                        ))
                    ) : (
                        <p></p>
                    )}
                </div>
                <div className='bottom-section'>
                    <div className='dat-platf'>
                        <div className='platf-btn'>
                            <a
                                href={post.url ? post.url : '#'}
                                target='_blank'
                                onClick={(e) => {
                                    if (!post.url) {
                                        e.preventDefault();
                                        console.log('URL not provided');
                                    }
                                }}
                            >
                                <h3>View On {platform}</h3>
                            </a>
                        </div>
                        <h3>{formatTime(new Date(post.date))}</h3>
                    </div>
                    {!isOnTokenCreate && (
                        <div className='link-btn'>
                            <button
                                className='link'
                                onClick={() => {
                                    navigate('TokenCreate', { state: { post: post, background, platform } });
                                    console.log('Navigating with post data:', post);
                                }}
                            >
                                <span className='span-link'>&#8250;</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default MessageCard;
