import axios from 'axios';
import { Source, Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';

export class ParserBackend {
    constructor(private readonly url: string, private readonly apiKey: string) {}

    public getSources = (): Promise<Source[]> =>
        axios
            .get(`${this.url}/feed/sources`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
            })
            .then((res) => res.data);

    public getLatestPosts = (source: Source, number = 20): Promise<Post[]> =>
        axios
            .get(`${this.url}/feed/${source.platform}/${source.username}`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
                params: {
                    size: number,
                },
            })
            .then((res) => {
                console.log('source:', source);
                return res.data;
            });

    public getGeneralPosts = (number = 20): Promise<Post[]> =>
        axios
            .get(`${this.url}/feed/general`, {
                headers: {
                    'x-api-key': this.apiKey,
                },
                params: {
                    size: number,
                },
            })
            .then((res) => {
                return res.data;
            });
}
