import { useState, useEffect } from 'react';
import FeedSource from '../FeedSource';
import '../MonitorFeed.css';
import { useBackend } from '../../Hooks/UseBackend';
import { Feed, SoundStatus } from '../../post-interface';
import { Post, Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import twitterSound from '../../utils/twitterSound.wav';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GeneralPosts } from '../GeneralPosts';

import { useFeedGeteway } from '../../Hooks/UseFeedGateway';
import { Api } from '@sodamnfoolish/sjc-backend-types';

export function MonitorFeed({ sources, isGeneralPostsVisible }: { sources: Source[]; isGeneralPostsVisible: boolean }) {
    const { getLatestPosts } = useBackend();
    const { feedGatewayClient } = useFeedGeteway();

    const [generalFeed, setGeneralFeed] = useState<Feed>({});
    const [feeds, setFeeds] = useState<Feed>({});
    const [soundsEnabled, setSoundsEnabled] = useState<SoundStatus>({});

    useEffect(() => {
        const fetchFeeds = async () => {
            const promises = sources.map(async (source) => {
                const posts = await getLatestPosts(source);
                return { username: source.username, posts };
            });

            const results = await Promise.all(promises);
            const newFeeds: Feed = {};

            results.forEach((result) => {
                newFeeds[result.username] = result.posts;
            });

            setFeeds(newFeeds);
        };

        if (sources.length > 0) fetchFeeds();
    }, [sources]);

    useEffect(() => {
        if (!feedGatewayClient) return;

        feedGatewayClient.on(Api.Feed.Gateway.Event.NewPost, async (post: Api.Feed.DTO.Post) => {
            try {
                const newFeeds = { ...feeds };
                const feed: Post[] = newFeeds[post.username] || [];
                feed.unshift(post);
                newFeeds[post.username] = feed;
                setFeeds(newFeeds);

                const newGeneralFeed = { ...generalFeed };
                const generalPosts = newGeneralFeed.Everyone || [];
                generalPosts.unshift(post);
                newGeneralFeed.Everyone = generalPosts;
                setGeneralFeed(newGeneralFeed);

                if (soundsEnabled[post.username]) {
                    await new Audio(twitterSound).play();
                }
            } catch (err) {
                console.error(err);
            }
        });

        feedGatewayClient.on(Api.Feed.Gateway.Event.PostUpdate, async (update: Api.Feed.DTO.PostUpdate) => {
            try {
                const newFeeds = { ...feeds };
                const feed = newFeeds[update.username] || [];
                const index = feed.findIndex((p) => p.id === update.id);
                let post = feed[index];
                post = { ...post, ...update };
                feed[index] = post;
                newFeeds[update.username] = feed;
                setFeeds(newFeeds);

                const newGeneralFeed = { ...generalFeed };
                const generalPosts = newGeneralFeed.Everyone || [];
                const generalIndex = generalPosts.findIndex((p) => p.id === update.id);
                let generalPost = generalPosts[generalIndex];
                generalPost = { ...generalPost, ...update };
                generalPosts[generalIndex] = generalPost;
                newGeneralFeed.Everyone = generalPosts;
                setGeneralFeed(newGeneralFeed);

                if (soundsEnabled[update.username]) {
                    await new Audio(twitterSound).play();
                }
            } catch (err) {
                console.error(err);
            }
        });

        feedGatewayClient.on(Api.Feed.Gateway.Event.SourceMaintenanceStopped, (source: Api.Feed.DTO.Source) => {
            console.log(Event.name);
            try {
                console.log('Source maintenance stopped', source);
                const newFeeds = { ...feeds };
                delete newFeeds[source.username];
                setFeeds(newFeeds);
                toast.error(`Source maintenance stopped ${source.username}`);
            } catch (err) {
                console.error(err);
                toast.error(`Failed to handle Source Maintanance Stopped event`);
            }
        });

        return () => {
            feedGatewayClient.off(Api.Feed.Gateway.Event.NewPost);
            feedGatewayClient.off(Api.Feed.Gateway.Event.PostUpdate);
            feedGatewayClient.off(Api.Feed.Gateway.Event.SourceMaintenanceStopped);
        };
    }, [sources, feedGatewayClient, feeds, soundsEnabled, twitterSound]);

    return (
        <section>
            <div className='body'>
                {isGeneralPostsVisible && <GeneralPosts generalFeed={generalFeed} setGeneralFeed={setGeneralFeed} />}
                {sources.length > 0 ? (
                    sources.map((source) => (
                        <div key={source.username}>
                            <FeedSource
                                posts={feeds[source.username] || []}
                                source={source}
                                soundsEnabled={soundsEnabled}
                                setSoundsEnabled={setSoundsEnabled}
                            />
                        </div>
                    ))
                ) : (
                    <div className='load'>Loading...</div>
                )}
            </div>
        </section>
    );
}

export default MonitorFeed;
