import MessageCard from './MessageCard';
import './FeedSource.css';
import { SoundStatus } from '../post-interface';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import { Post, Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';

const FeedSource = ({
    posts,
    source,
    soundsEnabled,
    setSoundsEnabled,
}: {
    posts: Post[];
    source: Source;
    soundsEnabled: SoundStatus;
    setSoundsEnabled: React.Dispatch<React.SetStateAction<SoundStatus>>;
}) => {
    const { platform, username } = source;

    function getSourceDetails(source: Source) {
        const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
            return base[platform] && (base[platform][username as keyof T] as string);
        };

        const color = getDetails(Api.Feed.Constants.Source.COLOR, platform);
        const image = getDetails(Api.Feed.Constants.Source.IMAGE, platform);
        const url = getDetails(Api.Feed.Constants.Source.URL, platform);

        return { color, image, url };
    }

    const { color, image, url } = getSourceDetails(source);

    const gradients = getComputedStyle(document.body);
    const currentGradient = gradients.getPropertyValue(color);

    return (
        <section>
            <div className='header-title'></div>
            <div className='container-form'>
                <div className='feed-source'>
                    <div className='icon-naming-link'>
                        <img className='icon' src={`data:image/jpeg;base64,${image}`} />
                        <div className='name-url'>
                            <h3>{source.username}</h3>
                            <a
                                className='link-to-channel'
                                href={url ? url : '#'}
                                target='_blank'
                                onClick={(e) => {
                                    if (!url) {
                                        e.preventDefault();
                                        console.log('URL not provided');
                                    }
                                }}
                            >
                                <h3>{url && url.includes('https://') ? url.slice(8) : url}</h3>
                            </a>
                        </div>
                    </div>
                    <div
                        className={`volume-check ${soundsEnabled[source.username] ? 'checked' : ''}`}
                        onClick={() => {
                            const newSoundsEnabled = { ...soundsEnabled };
                            newSoundsEnabled[source.username] = !newSoundsEnabled[source.username];
                            setSoundsEnabled(newSoundsEnabled);
                        }}
                    >
                        <div className='checkbox-icon'></div>
                    </div>
                </div>
                <div className='form'>
                    {posts ? (
                        posts.map((post, index) => <MessageCard post={post} key={index} background={currentGradient} platform={platform} />)
                    ) : (
                        <div>Loading...</div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default FeedSource;
