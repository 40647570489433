import React, { useState } from 'react';
import { useTelegram } from './UseTelegram';
import './Telegram.css';

export function TelegramLogin() {
    const { sendCode, signIn } = useTelegram();

    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [phoneCodeHash, setPhoneCodeHash] = useState<string>('');
    const [code, setCode] = useState<string>('');

    return (
        <div className='login-tg'>
            <div className='send-container'>
                <div>
                    <input
                        className='send-input'
                        value={phoneNumber}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(event.target.value)}
                        placeholder='123456789'
                    />
                </div>
                <div className='send-btn'>
                    <button className='button-accent' onClick={() => sendCode(phoneNumber).then((phoneCodeHash: string) => setPhoneCodeHash(phoneCodeHash))}>
                        Send Code
                    </button>
                </div>
            </div>
            <div className='sign-container'>
                <div>
                    <input className='sign-input' value={code} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setCode(event.target.value)} />
                </div>
                <div className='sign-btn'>
                    <button className='button-accent' onClick={() => signIn(phoneNumber, phoneCodeHash, code)}>
                        Sign In
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TelegramLogin;
