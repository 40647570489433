import '../TokenCreate.css';
import { useLocation } from 'react-router-dom';
import { useState, useRef, useEffect } from 'react';
import MessageCard from '../MessageCard';
import { useFeedGeteway } from '../../Hooks/UseFeedGateway';
import { DEPLOY_TOKEN_EVENT_NAME } from '../../utils/events';
import { TokenMetadata } from '../../utils/token-metadata';
import { Post } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto/post';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TelegramLogin } from '../Telegram/TelegramLogin';
import { useTelegram } from '../Telegram/UseTelegram';
import { RegisterChannel } from '../Telegram/RegisterChannel';

export function TokenCreate() {
    const { authorized } = useTelegram();
    const [telegramChannelUsername, setTelegramChannelUsername] = useState<string>('');
    const [autofillValue, setAutofillValue] = useState('');
    const [commentsAboveCode, setCommentsAboveCode] = useState<string>('https://t.me/ThisIsName');
    const preventSymbols = (event: { key: string; preventDefault: () => void }) => {
        const validCharacters = /^[a-zA-Z0-9_]+$/;

        if (!validCharacters.test(event.key)) {
            event.preventDefault();
        }
    };

    const location = useLocation();
    const { post, background, platform }: { post: Post; background: string; platform: string } = location.state || {};

    const { feedGatewayClient } = useFeedGeteway();

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageFile, setImageFile] = useState<File | null>(null);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    const [name, setName] = useState<string>('');
    const [symbol, setSymbol] = useState<string>('');
    const [description, setDescription] = useState<string>(post?.text || '');
    const [url, setUrl] = useState('');

    const [symbolAutocapChecked, setSymbolAutocapChecked] = useState(true);

    const [splAutoDeployChecked, setSplAutoDeployChecked] = useState(false);
    const [pumpAutoDeployChecked, setPumpAutoDeployChecked] = useState(false);
    const [ercAutoDeployChecked, setErcAutoDeployChecked] = useState(false);

    async function sendDeployTokenEvent(metadata: TokenMetadata) {
        await new Promise<boolean>((resolve) => {
            feedGatewayClient!.emit(DEPLOY_TOKEN_EVENT_NAME, metadata, () => {
                resolve(true);
            });
        });
    }

    const removeEmojis = (text: string) => {
        return text.replace(
            /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD83C-\uDBFF\uDC00-\uDFFF\u0023-\u0039]\uFE0F?|[\u200D\u20E3\u3297\u3299]\uFE0F?|[\u00A9\u00AE\u303D\u3030\u2B50\u2B55\u231A\u231B\u25AA\u25AB\u25B6\u25C0\u25FB\u25FC\u25FD\u25FE\u00A9\u00AE\u303D\u3030\u2B50\u2B55\u231A\u231B\u25AA\u25AB\u25B6\u25C0\u25FB\u25FC\u25FD\u25FE])+|\uD83D[\uDC00-\uDFFF]/g,
            '',
        );
    };

    const getFirstWord = (text: string, caps: boolean = false): string => {
        if (!text) return '';

        const removeUnwantedSegments = (input: string): string => {
            let result = input.replace(/::\[Reply\]/gi, '');
            result = result.replace(/@\w+/g, '');
            result = result.replace(/\d{2}:\d{2}:\d{2}/g, '');
            result = result.replace(/\[.*?\]/g, '');
            result = result.replace(/[^\w\s]|_/g, '');
            return result.trim();
        };

        const cleanText = removeEmojis(removeUnwantedSegments(text));

        let words = cleanText.split(/\s+/).filter((word) => word.length > 0);

        words = words.filter((word) => word.length > 1);

        let firstWord = '';

        if (cleanText.toLowerCase().startsWith('just in')) {
            const wordsAfterKeyword = words.slice(2);
            firstWord = wordsAfterKeyword[0] || '';
        } else if (cleanText.toLowerCase().startsWith('breaking')) {
            const wordsAfterKeyword = words.slice(1);
            firstWord = wordsAfterKeyword[0] || '';
        } else if (cleanText.toLowerCase().startsWith('rt')) {
            const wordsAfterKeyword = words.slice(1);
            firstWord = wordsAfterKeyword[0] || '';
        } else {
            firstWord = words[0] || '';
        }

        return caps ? firstWord.toUpperCase() : firstWord;
    };

    const capitalizeFirstLetter = (text: string) => {
        if (!text) return '';
        return text.charAt(0).toUpperCase() + text.slice(1);
    };

    const removeDoubleStar = (text: string) => {
        return text.replaceAll('*', '');
    };

    useEffect(() => {
        if (post && post.text) {
            setSymbol(getFirstWord(post.text, true));
            setName(capitalizeFirstLetter(getFirstWord(post.text, false)));
            setDescription(removeDoubleStar(post.text));
        }
    }, [post?.text]);

    const base64ToFile = (base64Data: string, fileName: string) => {
        const byteString = atob(base64Data.split(',')[1]);
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0];

        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new File([ab], fileName, { type: mimeString });
    };

    useEffect(() => {
        if (post && post.photos && post.photos.length > 0) {
            const base64Image = `data:image/jpeg;base64,${post.photos[0]}`;
            setImageSrc(base64Image);
            const file = base64ToFile(base64Image, 'postImage.jpg');
            setImageFile(file);
        }
    }, [post?.photos]);

    const imageClick = (index: number) => {
        const base64Image = `data:image/jpeg;base64,${post.photos[index]}`;
        setImageSrc(base64Image);

        const file = base64ToFile(base64Image, 'postImage.jpg');
        setImageFile(file);
    };

    const imageFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setImageSrc(imageUrl);
            setImageFile(file);
        }
    };

    async function uploadImageToIpfs(imageFile: File) {
        try {
            toast.info('Uploading image to IPFS...', {
                autoClose: 2500,
                closeOnClick: true,
                draggable: true,
            });
            const formData = new FormData();
            formData.append('file', imageFile);

            const metadata = JSON.stringify({ name: 'File name' });
            formData.append('pinataMetadata', metadata);

            const options = JSON.stringify({ cidVersion: 0 });
            formData.append('pinataOptions', options);

            const responseData = await axios.post('https://api.pinata.cloud/pinning/pinFileToIPFS', formData, {
                maxBodyLength: Infinity,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${process.env.REACT_APP_PINATA_JWT}`,
                },
            });

            const fileUrl = 'https://gateway.pinata.cloud/ipfs/' + responseData.data.IpfsHash;

            toast.success('Image uploaded successfully!', {
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
                draggable: true,
            });

            return fileUrl;
        } catch (error) {
            toast.error('Failed to upload image to IPFS!', {
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
                draggable: true,
            });

            throw new Error('Error uploading image to IPFS: ' + error);
        }
    }

    async function sendToSpl() {
        let url: string = '';
        if (imageFile) {
            url = await uploadImageToIpfs(imageFile);
            setUrl(url);
        }
        console.log('url', url);

        sendDeployTokenEvent({
            tokenName: name,
            tokenSymbol: symbol,
            tokenDescription: description,
            customImageUrl: url,
            autoDeploy: splAutoDeployChecked,
        })
            .then(() => {
                toast.success('Deploy event sent!');
            })
            .catch((error) => {
                toast.error(`Failed to send deploy event: ${error.message}`);
            });
    }

    return (
        <section className='card-settings-container'>
            <section className='card'>
                {post ? <MessageCard post={post} background={background} onImageClick={imageClick} platform={platform} /> : <p>Post not selected</p>}
            </section>
            <section className='sett-container'>
                <div className='naming'>
                    <h3>Name</h3>
                    <input
                        value={name}
                        onChange={(event) => {
                            setName(event.target.value);
                        }}
                        type='text'
                    />
                </div>
                <div className='symbols'>
                    <h3>Symbol</h3>
                    <input
                        type='text'
                        className='symbol-input'
                        value={symbol}
                        onChange={(event) => {
                            setSymbol(symbolAutocapChecked ? event.target.value.toUpperCase() : event.target.value);
                        }}
                    />
                    <input
                        type='checkbox'
                        className='symbol-checkbox'
                        checked={symbolAutocapChecked}
                        onChange={() => {
                            if (!symbolAutocapChecked) {
                                setSymbol(symbol.toUpperCase());
                            }
                            setSymbolAutocapChecked(!symbolAutocapChecked);
                        }}
                    />
                    <h3>AutoCap</h3>
                </div>
                <div className='description-case'>
                    <h3>Description</h3>
                    <textarea
                        value={description}
                        onChange={(event) => {
                            setDescription(event.target.value);
                        }}
                    />
                </div>
                <div className='img-case'>
                    <div className='img-text'>
                        <h3>Image</h3>
                        <h3>(SPL&Pump)</h3>
                    </div>
                    <div className='img-btn'>
                        <div className='img'>{imageSrc ? <img src={imageSrc} alt='Uploaded' /> : 'image from post'}</div>
                        <div className='btn-case'>
                            <div>
                                <button
                                    className='delete-btn'
                                    onClick={() => {
                                        setImageSrc(null);
                                        setImageFile(null);
                                        if (fileInputRef.current) {
                                            fileInputRef.current.value = '';
                                        }
                                    }}
                                ></button>
                            </div>
                            <div>
                                <label className='upload-icon'>
                                    <input type='file' accept='image/*' onChange={imageFileChange} ref={fileInputRef} />
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='send-case'>
                    <div className='send-spl'>
                        <h3>Send to SPL</h3>
                        <div className='auto-deploy'>
                            <input type='checkbox' onChange={() => setSplAutoDeployChecked(!splAutoDeployChecked)} />
                            <p>Auto Deploy</p>
                        </div>
                        <button className='spl' onClick={sendToSpl}>
                            Send to SPL{' '}
                        </button>
                    </div>
                    <div className='send-pump'>
                        <h3>Send to Pump</h3>
                        <div className='auto-deploy'>
                            <input type='checkbox' onChange={() => setPumpAutoDeployChecked(!pumpAutoDeployChecked)} />
                            <p>Auto Deploy</p>
                        </div>
                        <button className='pump' disabled={true}>
                            Send to PUMP{' '}
                        </button>
                    </div>
                    <div className='send-erc'>
                        <h3>Send to ERC</h3>
                        <div className='auto-deploy'>
                            <input type='checkbox' onChange={() => setErcAutoDeployChecked(!ercAutoDeployChecked)} />
                            <p>Auto Deploy</p>
                        </div>
                        <button className='erc' disabled={true}>
                            Send to ERC{' '}
                        </button>
                    </div>
                </div>
            </section>
            <section className='tg-channel'>
                <div>{authorized === true ? null : <TelegramLogin />}</div>
                <div className='tg'>
                    {authorized === true ? (
                        <RegisterChannel
                            telegramChannelUsername={telegramChannelUsername}
                            setTelegramChannelUsername={setTelegramChannelUsername}
                            autofillValue={autofillValue}
                            setCommentsAboveCode={setCommentsAboveCode}
                            commentsAboveCode={commentsAboveCode}
                            preventSymbols={preventSymbols}
                        />
                    ) : null}{' '}
                </div>
            </section>
        </section>
    );
}
function then(arg0: () => void) {
    throw new Error('Function not implemented.');
}
