import { useState, useEffect } from 'react';
import { useBackend } from '../Hooks/UseBackend';
import { Feed } from '../post-interface';
import MessageCard from './MessageCard';
import { Api } from '@sodamnfoolish/sjc-backend-types';
import './GeneralPosts.css';

export function GeneralPosts({ generalFeed, setGeneralFeed }: { generalFeed: Feed; setGeneralFeed: React.Dispatch<React.SetStateAction<Feed>> }) {
    const { getGeneralPosts } = useBackend();

    useEffect(() => {
        const fetchFeeds = async () => {
            try {
                const posts = await getGeneralPosts(20);
                const newFeeds: Feed = { Everyone: posts };

                setGeneralFeed(newFeeds);
            } catch (err) {
                console.error('Error fetching general posts:', err);
            }
        };

        fetchFeeds();
    }, []);

    const username = 'Everyone';

    return (
        <section>
            <div className='header-title'></div>
            <div className='container-form'>
                <div className='feed-source'>
                    <div className='name'>
                        <h3>{username}</h3>
                    </div>
                </div>
                <div className='form'>
                    {generalFeed.Everyone && generalFeed.Everyone.length > 0 ? (
                        generalFeed.Everyone.map((post, index) => {
                            const { platform, username } = post;

                            function getSourceDetails() {
                                const getDetails = <T extends Record<string, any>>(base: Record<string, T>, platform: string) => {
                                    return base[platform] && (base[platform][username as keyof T] as string);
                                };

                                const color = getDetails(Api.Feed.Constants.Source.COLOR, platform);
                                const image = getDetails(Api.Feed.Constants.Source.IMAGE, platform);
                                const url = getDetails(Api.Feed.Constants.Source.URL, platform);

                                return { color, image, url };
                            }

                            const { color } = getSourceDetails();

                            const gradients = getComputedStyle(document.body);
                            const currentGradient = gradients.getPropertyValue(color);

                            return <MessageCard post={post} key={index} background={currentGradient} platform={platform} />;
                        })
                    ) : (
                        <div className='load'>Loading...</div>
                    )}
                </div>
            </div>
        </section>
    );
}
