import { NavLink } from 'react-router-dom';
import './Navbar.css';

export function Navbar() {
    return (
        <nav>
            <ul>
                <li>
                    <NavLink className='nav-monitor-feed-page nav-container' to='/'>
                        <span className='nav-active-effect'></span>
                        <span className='nav-icon'></span>
                        <span className='nav-item'>Monitor Feed</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink className='nav-token-create nav-container' to='TokenCreate'>
                        <span className='nav-active-effect'></span>
                        <span className='nav-icon'></span>
                        <span className='nav-item'>Token Create</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
}
