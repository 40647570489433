import './styles.css';
import { Navbar } from './Components/Navbar';
import { MonitorFeed } from './Components/pages/MonitorFeed';
import { Route, Routes } from 'react-router-dom';
import { TokenCreate } from './Components/pages/TokenCreate';
import { Header } from './Components/Header';
import { useEffect, useState } from 'react';
import { useBackend } from './Hooks/UseBackend';
import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { ToastContainer } from 'react-toastify';

function App() {
    const [sources, setSources] = useState<Source[]>([]);
    const [visibleSources, setVisibleSources] = useState<Source[]>([]);
    const [isGeneralPostsVisible, setGeneralPostsVisible] = useState(true);

    const { getSources } = useBackend();

    useEffect(() => {
        getSources().then((data) => {
            console.log('sources', data);
            setSources(data);
            setVisibleSources(data);
        });
    }, []);

    const handleToggleSource = (source: Source, checked: boolean) => {
        if (checked) {
            setVisibleSources((prev) => [...prev, source]);
        } else {
            setVisibleSources((prev) => prev.filter((s) => s.username !== source.username));
        }
    };

    const visibleGeneralPosts = (checked: boolean) => {
        setGeneralPostsVisible(checked);
    };

    return (
        <div className='container'>
            <section className='header'>
                <Navbar />
                <div className='inputs'>
                    <div className='dyn-inputs'>
                        <label className={`custom-checkbox ${isGeneralPostsVisible ? 'checked' : ''}`}>
                            <span className='label-text'>Everyone</span>
                            <input type='checkbox' checked={isGeneralPostsVisible} onChange={(e) => visibleGeneralPosts(e.target.checked)} />
                            <span className='custom-checkbox-box'>
                                <span className='checkmark'>✔</span>
                                <span className='cross'>✖</span>
                            </span>
                        </label>
                    </div>
                    {sources.map((source, index) => (
                        <Header
                            key={index}
                            source={source}
                            onToggle={handleToggleSource}
                            isChecked={visibleSources.some((s) => s.username === source.username)}
                        />
                    ))}
                </div>
            </section>
            <section>
                <Routes>
                    <Route path='/' element={<MonitorFeed sources={visibleSources} isGeneralPostsVisible={isGeneralPostsVisible} />} />
                    <Route path='TokenCreate' element={<TokenCreate />} />
                </Routes>
            </section>
            <section className='toast-cont'>
                <ToastContainer position='bottom-right' theme='dark' />
            </section>
        </div>
    );
}

export default App;
