import React from 'react';
import './Header.css';
import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';

interface HeaderProps {
    source: Source;
    onToggle: (source: Source, checked: boolean) => void;
    isChecked: boolean;
}

export const Header = ({ source, onToggle, isChecked }: HeaderProps) => {
    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onToggle(source, event.target.checked);
    };

    return (
        <section>
            <div className='dyn-inputs'>
                <label className={`custom-checkbox ${isChecked ? 'checked' : ''}`}>
                    <span className='label-text'>{source.username}</span>
                    <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
                    <span className='custom-checkbox-box'>
                        <span className='checkmark'>✔</span>
                        <span className='cross'>✖</span>
                    </span>
                </label>
            </div>
        </section>
    );
};

export default Header;
