import { Source } from '@sodamnfoolish/sjc-backend-types/src/api/feed/dto';
import { ParserBackend } from '../utils/parser-backend';

export const useBackend = () => {
    const parserBackend = new ParserBackend(process.env.REACT_APP_BACKEND_URL!, process.env.REACT_APP_BACKEND_APIKEY!);

    const getSources = async () => {
        const sources = await parserBackend.getSources();
        return sources;
    };

    const getLatestPosts = async (source: Source, number = 20) => {
        const posts = await parserBackend.getLatestPosts(source, number);
        console.log('posts', posts);
        return posts;
    };
    const getGeneralPosts = async (number = 20) => {
        const posts = await parserBackend.getGeneralPosts(number);
        console.log('posts', posts);
        return posts;
    };

    return { getSources, getLatestPosts, getGeneralPosts };
};
